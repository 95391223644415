import React from 'react';
import Layout from '../../../components/layout';
import RegistrationFrom from '../../../components/registration-form/registration-from';

export default function RegisterUnido(props) {
  return (
    <Layout>
      <RegistrationFrom
        imageName='unido'
        imageAlt='unido'
        source='UNIDO'
        page='unido'
      />
    </Layout>
  );
}
